import React, { useState, useEffect } from 'react';
import bridge from '@vkontakte/vk-bridge';
import {View} from '@vkontakte/vkui'
import '@vkontakte/vkui/dist/vkui.css';

import PanelMain from './panels/main';
import APIEye3GetHashTrap from './api/eye3/trap.get_hash';
import { getSearchObject } from 'helpers/api';

export const DESKTOP_WEB = 'desktop_web';
export const MOBILE_WEB = 'mobile_web';
export const MOBILE_IPHONE = 'mobile_iphone';
export const MOBILE_ANDROID = 'mobile_android';

const App = () => {
	const [activePanel, setActivePanel] = useState('main');

	const handleGo = () => {
		APIEye3GetHashTrap({
			hash: window.location.hash.slice(1)
		}).then((res) => {
			if (!res.status) {
				//console.log('trap: ', res);
				return;
			}

			if (getSearchObject().vk_platform === DESKTOP_WEB || getSearchObject().vk_platform === MOBILE_WEB) {
				window.open(res.url, '_blank');
			}
			else if (getSearchObject().vk_platform === MOBILE_IPHONE) {
				document.location.replace(res.url);
			}
			else if (getSearchObject().vk_platform === MOBILE_ANDROID) {
				window.open(res.url);
			}
		})
	}

	useEffect(() => {
		bridge.subscribe(({ detail: { type, data }}) => {
			if (type === 'VKWebAppUpdateConfig') {
				const schemeAttribute = document.createAttribute('scheme');
				schemeAttribute.value = data.scheme ? data.scheme : 'bright_light';
				document.body.attributes.setNamedItem(schemeAttribute);
			}
		});
	}, []);

	const goPanel = e => {
		setActivePanel(e.currentTarget.dataset.to);
	};

	return (
		<View id='main' activePanel={activePanel}>
			<PanelMain id='main' goPanel={goPanel} handleGo={handleGo}></PanelMain>
		</View>
	);
}

export default App;

