import React from 'react';
import {Button, Panel, Placeholder} from '@vkontakte/vkui'

const PanelMain = (props) => {
    return (
        <Panel id={props.id}>
            <Placeholder
                header='Вы собираетесь перейти по внешней ссылке'
                action={
                    <Button size='l' onClick={props.handleGo} stretched>
                        Перейти по ссылке
                    </Button>
                }
                stretched
            >
                Для этого нажмите кнопку ниже
            </Placeholder>
        </Panel>
    )
}

export default PanelMain;