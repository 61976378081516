import { 
    callGetEYE3Api,
    getSearchObject
} from 'helpers/api';

async function APIEye3GetHashTrap({hash}) {
    try {
        const response = await callGetEYE3Api('trap.get_hash', {
            hash: hash,
            ...getSearchObject()
        });
        return response;
    } catch (e) {
        throw String('APIEye3GetHashTrap::: ' + JSON.stringify(e));
    }
}

export default APIEye3GetHashTrap;